<script setup lang="ts">
const props = defineProps<{
	name: string
	label?: string
}>();
</script>

<template>
	<span class="flag">
		<img class="flag__item" :src="`/images/flags/flag-${props.name.toLocaleLowerCase()}.svg`" aria-hidden="true" />
	</span>
</template>
