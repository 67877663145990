<script setup lang="ts">
const props = defineProps<{
	type?: 'entry'
}>();

const route = useRoute();
const coreSearch = ref();

// INFO: we have to track the CoreSearch open state here because
// anytime we click outside the CoreSearch component it will close and
// if we click on the search button again when the frame is open it will just flicker
const searchState = ref(false);
const toggleSearch = () => {
	if (coreSearch) {
		searchState.value = !searchState.value;
		coreSearch.value.externalToggle(searchState.value);
	}
};
</script>

<template>
	<header class="header" :class="{ 'header--entry': props.type === 'entry' }">
		<div class="header__container">
			<div class="header__start">
				<Logo />
			</div>

			<div class="header__center" v-if="props.type !== 'entry' && route.name !== 'app.search'">
				<ButtonIcon
					:label="$t('props.openSearch')"
					icon="search"
					v-if="!$screen.md"
					@click="toggleSearch"
				/>
				<CoreSearch
					mode="toggle"
					:item-count="5"
					:infinite-scroll="false"
					ref="coreSearch"
				/>
			</div>
			<div class="header__end" v-if="props.type !== 'entry'">
				<ClientOnly>
					<HeaderActions />
				</ClientOnly>
			</div>
		</div>
	</header>
</template>
