<script setup lang="ts">
import { NavigationId, NavigationItems, filterNavigationItems, userHasAccess } from '@/constants/navigation';

const mainGroup = filterNavigationItems('main');
const primaryGroup = filterNavigationItems('primary');
const secondaryGroup = filterNavigationItems('secondary');
const tertiaryGroup = filterNavigationItems('tertiary');

const userOP = ref();
const { data: userData, pending } = useUserData();

const { user } = useFirebase();
const hasAdministrationPrivileges = ref(false);

// watch user
watch(user, async (user) => {
	if (!user)
		return;
	hasAdministrationPrivileges.value = await userHasAccess(NavigationItems.find(x => x.id === NavigationId.Administration)!, user);
}, { immediate: true });
</script>

<template>
	<template v-if="!pending">
		<button type="button" class="header-user" @click="e => userOP?.toggle(e)">
			<UserFrame
				:image="userData?.portraitFileName"
				:alt="userData?.name"
				:frame="userData?.pictureFrameSrc"
				size="sm"
			/>
			<span class="header-user__content">
				<span class="header-user__name" v-if="userData && userData.name">{{ userData.name }}</span>
				<span class="header-user__username" v-if="userData">@{{ userData.username }}</span>
			</span>
			<Icon name="nav-arrow-down" />
		</button>
	</template>
	<template v-else>
		<div class="header-user">
			<Skeleton size="4rem" circle />
			<span class="d:flex flex:col gap:4">
				<Skeleton size="10rem 1.6rem" />
				<Skeleton size="6rem 1rem" />
			</span>
			<Skeleton size="2.4rem" />
		</div>
	</template>

	<PvOverlayPanel ref="userOP">
		<Dropdown>
			<li class="dropdown__item dropdown__item--user" v-if="!$screen.sm">
				<span class="header-user__name" v-if="userData && userData.name">{{ userData.name }}</span>
				<span class="header-user__username" v-if="userData">@{{ userData.username }}</span>
			</li>
			<DropdownItem
				:label="$t(`nav.${item.i18nKey}`)"
				:link-to="{ name: item.name }"
				v-for="item in mainGroup"
				:key="item.id"
				@click="(e: MouseEvent) => userOP?.toggle(e)"
			/>
			<DropdownSeparator />
			<DropdownItem
				:label="$t(`nav.${item.i18nKey}`)"
				:link-to="{ name: item.name }"
				v-for="item in primaryGroup"
				:key="item.id"
				@click="(e: MouseEvent) => userOP?.toggle(e)"
			/>
			<DropdownSeparator />
			<DropdownItem
				:label="$t(`nav.${item.i18nKey}`)"
				:link-to="{ name: item.name }"
				v-for="item in secondaryGroup"
				:key="item.id"
				@click="(e: MouseEvent) => userOP?.toggle(e)"
			/>
			<DropdownSeparator />
			<DropdownItem
				:label="$t(`nav.${item.i18nKey}`)"
				:link-to="{ name: item.name }"
				:severity="item.severity"
				v-for="item in tertiaryGroup.filter(x => x.id !== NavigationId.Administration || (x.id === NavigationId.Administration && hasAdministrationPrivileges))"
				:key="item.id"
				@click="(e: MouseEvent) => userOP?.toggle(e)"
			/>
			<DropdownItem v-if="!$screen.md && !userData?.isPatreonSupporter">
				<Button
					class="mys:8"
					:label="$t('actions.supportUs')"
					severity="primary"
					icon="heart"
					block
					link-to="https://www.patreon.com/readfolio"
					target="_blank"
				/>
			</DropdownItem>
		</Dropdown>
	</PvOverlayPanel>
</template>
