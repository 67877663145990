<script setup lang="ts">
const { user } = useFirebase();
</script>

<template>
	<div class="footer-actions" v-if="!user">
		<Button :label="$t('actions.login')" icon="log-in" :link-to="{ name: 'app.login' }" />
		<Button
			:label="$t('actions.signUp')"
			severity="primary"
			gradient
			:link-to="{ name: 'app.register' }"
		/>
	</div>
</template>
