<script setup lang="ts">
const { pending, isAuthenticated } = useFirebase();
</script>

<template>
	<template v-if="pending">
		<div class="header__actions">
			<Skeleton size="10rem 4.2rem" />
		</div>
	</template>
	<template v-else>
		<template v-if="!isAuthenticated">
			<div class="header__actions" v-if="$screen.lg">
				<Button :label="$t('actions.login')" icon="log-in" :link-to="{ name: 'app.login' }" />
				<Button
					:label="$t('actions.signUp')"
					severity="primary"
					gradient
					:link-to="{ name: 'app.register' }"
				/>
			</div>
			<div class="header__actions" v-else>
				<Button
					:label="$t('actions.enter')"
					icon="log-in"
					severity="primary"
					gradient
					:link-to="{ name: 'app.register' }"
				/>
			</div>
		</template>
		<HeaderUser v-else />
	</template>
</template>
