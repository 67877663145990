<script setup lang="ts">
let isAuthenticated;

if (process.client)
	isAuthenticated = useFirebase().isAuthenticated;
else
	isAuthenticated = ref(false);

const link = computed(() => {
	if (isAuthenticated.value)
		return { name: 'app.profile.explore' };
	return { name: 'app.index' };
});
</script>

<template>
	<div class="logo">
		<NuxtLink :to="link">
			<span class="logo__text">Readfol.io</span>
			<span class="logo__badge">Draft</span>
		</NuxtLink>
	</div>
</template>
