<template>
	<div class="footer-end">
		<div class="footer-end__start">
			<Copyright />
		</div>
		<div class="footer-end__end">
			<div class="footer-end__links">
				<NuxtLink :to="{ name: 'app.termsAndConditions' }" class="footer-end__link">
					<span class="btn__text">{{ $t('footer.termsConditions') }}</span>
				</NuxtLink>
				<NuxtLink :to="{ name: 'app.privacyPolicy' }" class="footer-end__link">
					<span class="btn__text">{{ $t('footer.privacyPolicy') }}</span>
				</NuxtLink>
			</div>
			<ClientOnly>
				<LanguageSwitcher />
			</ClientOnly>
		</div>
	</div>
</template>
