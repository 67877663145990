<script setup lang="ts">
const { $pwa } = useNuxtApp();

const installPwa = async () => {
	await $pwa?.install();
};
</script>

<template>
	<div class="footer-start">
		<div class="footer-start__start">
			<div>
				<Logo />
			</div>
			<span class="footer-start__title">{{ $t('index.hero.title.primary') }}</span>
			<ClientOnly>
				<FooterActions />
			</ClientOnly>
		</div>
		<div class="footer-start__end">
			<ul class="footer-start__list">
				<li class="footer-start__item">
					<NuxtLink :to="{ name: 'app.faq' }" class="footer-start__link">
						<span>{{ $t('footer.faq') }}</span>
					</NuxtLink>
				</li>
				<li class="footer-start__item">
					<NuxtLink :to="{ name: 'app.contactUs' }" class="footer-start__link">
						<span>{{ $t('footer.contactUs') }}</span>
					</NuxtLink>
				</li>
			</ul>
			<ul class="footer-start__social-list">
				<li class="footer-start__social-item">
					<span class="footer-start__social-title">{{ $t('footer.socialTitle') }}:</span>
				</li>
				<li class="footer-start__social-item">
					<NuxtLink to="https://discord.com/invite/2XjfBKhDee" target="_blank" class="footer-start__social-link footer-start__social-link--discord">
						<Icon name="social-discord" />
						<span class="aria:sr">Discord</span>
					</NuxtLink>
				</li>
				<li class="footer-start__social-item">
					<NuxtLink to="https://www.instagram.com/readfol.io/" target="_blank" class="footer-start__social-link footer-start__social-link--instagram">
						<Icon name="social-instagram" />
						<span class="aria:sr">Instagram</span>
					</NuxtLink>
				</li>
			</ul>
			<div class="footer-start__download" v-if="$pwa?.showInstallPrompt">
				<Button
					:label="$t('actions.pwaInstall')"
					severity="secondary"
					shadow
					@click="installPwa"
				/>
			</div>
		</div>
	</div>
</template>
