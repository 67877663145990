<script setup lang="ts">
import type { LocaleObject } from '@nuxtjs/i18n/dist/runtime/composables';

const { locale, locales, setLocale } = useI18n();

const availableLocales = computed(() => locales.value.filter(x => (x as LocaleObject).code !== locale.value));

const selectedLocale = ref(locales.value.find(x => (x as LocaleObject).code === locale.value));
</script>

<template>
	<form>
		<div class="language-switcher">
			<PvDropdown :options="availableLocales" option-label="name" v-model="selectedLocale">
				<template #value="slotProps">
					<div class="language-switcher__value" v-if="slotProps.value">
						<Flag :name="slotProps.value.code.toLowerCase()" />
						<div>{{ slotProps.value.name }}</div>
					</div>
					<span v-else>
						{{ slotProps.placeholder }}
					</span>
				</template>
				<template #option="{ option }">
					<div class="language-switcher__item" @click="setLocale(option.code)">
						<Flag :name="option.code.toLowerCase()" />
						<div>{{ option.name }}</div>
					</div>
				</template>
			</PvDropdown>
		</div>
	</form>
</template>
