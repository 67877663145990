<script setup lang="ts">
const props = defineProps<{
	type?: 'entry'
	noStart?: boolean
	noEnd?: boolean
}>();
</script>

<template>
	<footer class="footer" :class="{ 'footer--entry': props.type === 'entry' }">
		<div class="footer__container">
			<FooterStart v-if="!props.noStart" />
			<FooterEnd v-if="!props.noEnd" />
		</div>
	</footer>
</template>
