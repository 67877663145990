import type { User } from 'firebase/auth';

export enum NavigationId {
	Explore = 0,
	MyPortfolio = 1,
	MyLibrary = 2,
	Wishlist = 3,
	CurrentlyReading = 4,
	RatingCollection = 5,
	Friends = 6,
	Challenges = 7,
	Settings = 8,
	LogOut = 9,
	Administration = 10,
};
export type NavigationGroup = 'main' | 'primary' | 'secondary' | 'tertiary';

export interface INavigationItem {
	id: NavigationId
	i18nKey: string
	name: string
	icon: string
	group: NavigationGroup
	roles?: string[] | null
	severity?: string | null
}

export const userHasAccess = async (navigationItem: INavigationItem, user: User | null): Promise<boolean> => {
	// if there are no roles then everyone can see
	if (!navigationItem.roles)
		return true;
	// if user is null and roles are assigned the user can't see
	if (user === null)
		return false;

	const tokenResult = await user.getIdTokenResult();

	for (const role of navigationItem.roles) {
		if (tokenResult.claims[role] !== true)
			return false;
	}

	return true;
};

export const NavigationItems: INavigationItem[] = [
	{
		id: NavigationId.Explore,
		i18nKey: 'explore',
		name: 'app.profile.explore',
		icon: 'globe',
		group: 'main',
	},
	{
		id: NavigationId.MyPortfolio,
		i18nKey: 'myPortfolio',
		name: 'app.myPortfolio',
		icon: 'user',
		group: 'main',
	},
	{
		id: NavigationId.MyLibrary,
		i18nKey: 'myLibrary',
		name: 'app.profile.myLibrary',
		icon: 'book',
		group: 'primary',
	},
	{
		id: NavigationId.Wishlist,
		i18nKey: 'wishlist',
		name: 'app.profile.wishlist',
		icon: 'favorite-book',
		group: 'primary',
	},
	{
		id: NavigationId.CurrentlyReading,
		i18nKey: 'currentlyReading',
		name: 'app.profile.currentlyReading',
		icon: 'open-book',
		group: 'primary',
	},
	{
		id: NavigationId.RatingCollection,
		i18nKey: 'ratingCollection',
		name: 'app.profile.ratingCollection',
		icon: 'star',
		group: 'primary',
	},
	{
		id: NavigationId.Friends,
		i18nKey: 'friends',
		name: 'app.profile.friends',
		icon: 'user-love',
		group: 'secondary',
	},
	{
		id: NavigationId.Challenges,
		i18nKey: 'challenges',
		name: 'app.profile.challenges',
		icon: 'crown',
		group: 'secondary',
	},
	{
		id: NavigationId.Settings,
		i18nKey: 'settings',
		name: 'app.profile.settings',
		icon: 'settings',
		group: 'tertiary',
	},
	{
		id: NavigationId.Administration,
		i18nKey: 'administration',
		name: 'app.profile.administration',
		icon: 'tools',
		group: 'tertiary',
		roles: ['admin'],
	},
	{
		id: NavigationId.LogOut,
		i18nKey: 'logOut',
		name: 'app.logout',
		icon: 'log-out',
		group: 'tertiary',
		severity: 'error',
	},
];

export const filterNavigationItems = (group: NavigationGroup): INavigationItem[] => {
	return NavigationItems.filter(item => item.group === group);
};
